import {
  fetchAlgStrategyPage,
  handleAlgStrategyAdd,
  deleteAlgStrategy,
  updateStrategies,
} from '@/api/setting';
import Pagination from '@/components/pagination';

const postDatas = {
  strategyCode: '',
  status: '',
  remark: '',
};
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      options: {
        status: [
          {
            label: 'off',
            value: 'off',
          },
          {
            label: 'on',
            value: 'on',
          },
        ],
      },
      total: 0,
      postData: {},
      updatePostData: {},
      list: [],
      editedDataList: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      updateDialog: {
        visible: false,
        title: '--',
        type: '',
      },

      rules: {
        strategyCode: [{ required: true, message: 'Strategy Code 不能为空' }],
        status: [{ required: true, message: 'status不能为空' }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      fetchAlgStrategyPage(this.filterForm)
        .then((res) => {
          if (res.code == 200) {
            this.list = res.result.records;
            this.editedDataList = res.result.records;
            this.total = res.result.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          deleteAlgStrategy(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      const _this = this;
      this.dialog.type = type;
      switch (type) {
        case 'add':
          _this.dialog.title = '添加';
          break;
        default:
          _this.dialog.title = '编辑';
      }
      this.dialog.visible = true;
      if (type === 'edit' && row) {
        this.postData = Object.assign({}, row);
      }
    },
    openUpdateDialog() {
      const _this = this;
      _this.updateDialog.title = '编辑策略比例';
      this.updateDialog.visible = true;
    },
    // 提交弹窗
    submitDialog(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          handleAlgStrategyAdd(this.postData).then((res) => {
            if (res.code == 200) {
              this.$message.success(
                this.dialog.type === 'add'
                  ? '添加成功!'
                  : this.dialog.type === 'edit'
                  ? '更新成功！'
                  : ''
              );
              this.loading.submitBtn = false;
              this.closeDialog(formName);
              this.getList();
            } else {
              this.$message.error(res.message);
              this.loading.submitBtn = false;
            }
          });
          this.$refs[formName].clearValidate();
        }
      });
    },

    submitUpdateDialog(formName) {
      this.loading.submitBtn = true;
      updateStrategies(this.editedDataList).then((res) => {
        if (res.code == 200) {
          this.$message.success(' 更新成功！');
          this.loading.submitBtn = false;
          this.closeUpdateDialog(formName);
          this.getList();
        } else {
          this.$message.error(res.message);
          this.loading.submitBtn = false;
        }
      });
    },

    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.postData = postDatas;
      this.dialog.visible = false;
    },
    // 关闭弹窗
    closeUpdateDialog(formName) {
      this.$refs[formName].resetFields();
      this.updatePostData = {};
      this.updateDialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
